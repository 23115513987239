export default class NodoOpl {
    constructor() {
        this.tipo = undefined
        this.operadores = []
        this.nodos = []

        this.funcion = undefined
        this.texto = undefined
        this.fuente = undefined
        this.variable = undefined
        this.fuente_comparacion = undefined
        this.variable_comparacion = undefined
        this.constante_comparacion = undefined
    }
}