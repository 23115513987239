<template>
  <div>
    <v-card elevation="2">
      <v-card-title>Edición de la regla {{ regla.nombre }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="regla.nombre"
                label="Nombre de la Regla"
                :rules="
                  required.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="regla.descripcion"
                label="Descripción"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 250) || 'Máximo 250 caracteres'
                  )
                "
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div
                v-if="loadingClasificacion"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingClasificacion"
                v-model="regla.clasificacion_id"
                :items="clasificaciones"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Clasificación"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <div
                v-if="loadingTipoRegla"
                class="spinner-border"
                role="status"
              ></div>
              <v-select
                v-if="!loadingTipoRegla"
                v-model="regla.tipo_regla_id"
                :items="tiposRegla"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Tipo de Regla"
              ></v-select>
            </v-col>
          </v-row>

          <v-row style="display:none">
            <v-col cols="12" md="6">
              <v-slider
                v-model="peso"
                label="Peso"
                max="100"
                min="0"
                hide-details
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="regla.peso"
                    class="mt-0 pt-0"
                    hide-details
                    single-line
                    type="number"
                    style="width: 60px"
                    readonly
                  ></v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="12" >
              <v-card elevation="2" tile style="text-align: center; padding:20px 0 20px 0; background-color: #f9f9f9">
                <nodo :elemento="regla.expresion" :fuentes="fuentes" :funciones="funciones"></nodo>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2" md="2">
              <v-switch v-model="regla.activo" :label="`Estado`"></v-switch>
            </v-col>
             <v-col cols="6" md="6">
              <v-text-field
                v-if="!regla.activo"
                v-model="regla.causaInactivacion"
                label="Causa de la inactivación"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 250) || 'Máximo 250 caracteres'
                  )
                "
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <pre>
              {{ regla.expresion }}
              </pre>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReglaService from "@/services/regla.service";
import validationField from "@/util/validationField";
import LayoutService from "@/services/layout.service";
import ListaService from "@/services/lista.service";
import NodoOpl from "./NodoOpl.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      regla: {
        nombre: undefined,
        descripcion: undefined,
        clasificacion_id: undefined,
        tipo_regla_id: undefined,
        peso: 0,
        expresion: {},
        activo: true,
        causaInactivacion: undefined
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      ordenRules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 1) || "Min 1 caracteres",
      ],
      peso: 0,
      fuentes: [],
      clasificaciones: [],
      tiposRegla: [],
      funciones: [],
      loading: false,
      loadingClasificacion: false,
      loadingTipoRegla: false,
      loadingLayouts: false,
      loadingSave: false,
      message: "",
      dialog: false,
    };
  },
  components: {
    nodo: () => import("./Nodo.vue"),
  },
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {
    peso(newVal) {
      this.regla.peso = newVal / 100;
    },
  },
  mounted() {
    this.regla.expresion = new NodoOpl()

    if (!this.permissions.includes("Definición de reglas de calidad"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_regla")) {
      this.regla = JSON.parse(localStorage.getItem("datos_regla"));
      this.regla.expresion = JSON.parse(this.regla.expresion)
    }

    this.fetchLayouts();
    this.fetchClasificaciones();
    this.fetchTiposRegla();
    this.fetchFunciones();
  },
  methods: {
    save() {
      this.loadingSave = true;
      this.regla.expresion = JSON.stringify(this.regla.expresion)
      console.log(this.variable);
      if (this.regla.hasOwnProperty("id")) {
        ReglaService.editItem(this.regla).then(
          (response) => {
            this.$router.push({ path: `/calidad/reglas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        ReglaService.newItem(this.regla).then(
          (response) => {
            this.$router.push({ path: `/calidad/reglas` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchLayouts(cadenaBusqueda) {
      this.loadingLayouts = true;
      if(cadenaBusqueda==""||cadenaBusqueda==undefined)
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      LayoutService.getItems(cadenaBusqueda).then(
        (response) => {
          this.fuentes = response.data.map(function f(x) {
            return {
              id: x.id,
              nombre: x.nombre
            };
          });
          // this.fuentes = response.data;
          this.loadingLayouts = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLayouts = false;
        }
      );
    },
    fetchClasificaciones(cadenaBusqueda) {
      this.loadingTransformaciones = true;
      ListaService.getItems('6', cadenaBusqueda).then(
      // ListaService.getClasificaciones().then(
        (response) => {
          console.log(response.data)
          this.clasificaciones = response.data;
          this.loadingTransformaciones = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTransformaciones = false;
        }
      );
    },
    fetchTiposRegla(cadenaBusqueda) {
      this.loadingTransformaciones = true;
      ListaService.getItems('7', cadenaBusqueda).then(
      // ListaService.getTiposRegla().then(
        (response) => {
          console.log(response.data)
          this.tiposRegla = response.data;
          this.loadingTransformaciones = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTransformaciones = false;
        }
      );
    },
    fetchFunciones(cadenaBusqueda) {
      this.loadingTransformaciones = true;
      if(cadenaBusqueda==""||cadenaBusqueda==undefined)
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      ListaService.getItems('8', cadenaBusqueda).then(
      //ListaService.getFunciones().then(
        (response) => {
          console.log(response.data)
          this.funciones = response.data.map(function f(x) {
            return {
              id: x.id,
              valor_texto: x.valor_texto,
              descripcion: x.descripcion,
              abreviatura: x.abreviatura
            };
          });
          // this.funciones = response.data;
          this.loadingTransformaciones = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingTransformaciones = false;
        }
      );
    },
    agregarTransformacion() {
      this.variable.transformaciones.push({
        transformacion: "",
        valorA: "",
        valorB: "",
      });
    },
    deleteItem(transformacion) {
      let i = this.variable.transformaciones.indexOf(transformacion);
      this.variable.transformaciones.splice(i, 1);
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/calidad/reglas" });
    },
  },
};
</script>

<style>
</style>
